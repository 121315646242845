import { RouteRecordRaw } from 'vue-router';

const rotasAplicacao: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Aplicacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'sem-privilegio',
        name: 'SemPrivilegio',
        meta: {
          titulo: 'Você não possui autorização!',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "core" */ '@/core/views/SemPrivilegio.vue'),
      },
      {
        path: 'configuracoes',
        name: 'Configuracoes',
        meta: {
          titulo: 'Configurações',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/Configuracoes.vue'),
      },
      {
        path: 'configuracoes-emails',
        name: 'ConfiguracoesEmails',
        meta: {
          titulo: 'Configurações de E-mails',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/ConfiguracoesEmails.vue'),
      },
      {
        path: 'meu-negocio',
        name: 'MeuNegocio',
        meta: {
          titulo: 'Meu Negócio',
          identificadorPermissao: 'MEU_NEGOCIO',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/MeuNegocio.vue'),
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        meta: {
          titulo: 'Usuários',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioListagem.vue'),
      },
      {
        path: 'usuarios/cadastrar',
        name: 'UsuarioCadastroCriar',
        meta: {
          titulo: 'Cadastrar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/:codigo',
        name: 'UsuarioCadastroEditar',
        meta: {
          titulo: 'Editar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/meus-dados',
        name: 'UsuarioMeusDados',
        meta: {
          titulo: 'Meus Dados',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/MeusDados.vue'),
      },
      {
        path: 'marcas',
        name: 'Marcas',
        meta: {
          titulo: 'Marcas',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaListagem.vue'),
      },
      {
        path: 'marcas/cadastrar',
        name: 'MarcaCadastroCriar',
        meta: {
          titulo: 'Cadastrar Marca',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaCadastro.vue'),
      },
      {
        path: 'marcas/:codigo',
        name: 'MarcaCadastroEditar',
        meta: {
          titulo: 'Editar Marca',
          identificadorPermissao: 'MARCAS',
        },
        component: () => import(/* webpackChunkName: "marcas" */ '@/views/Marcas/MarcaCadastro.vue'),
      },
      {
        path: 'clientes',
        name: 'Clientes',
        meta: {
          titulo: 'Clientes',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteListagem.vue'),
      },
      {
        path: 'clientes/cadastrar',
        name: 'ClienteCadastroCriar',
        meta: {
          titulo: 'Cadastrar Cliente',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteCadastro.vue'),
      },
      {
        path: 'clientes/:codigo',
        name: 'ClienteCadastroEditar',
        meta: {
          titulo: 'Editar Cliente',
          identificadorPermissao: 'CLIENTES',
        },
        component: () => import(/* webpackChunkName: "clientes" */ '@/views/Clientes/ClienteCadastro.vue'),
      },
      {
        path: 'areas',
        name: 'Areas',
        meta: {
          titulo: 'Áreas',
          identificadorPermissao: 'AREAS',
        },
        component: () => import(/* webpackChunkName: "areas" */ '@/views/Areas/AreaListagem.vue'),
      },
      {
        path: 'areas/cadastrar',
        name: 'AreaCadastroCriar',
        meta: {
          titulo: 'Nova Área',
          identificadorPermissao: 'AREAS',
        },
        component: () => import(/* webpackChunkName: "areas" */ '@/views/Areas/AreaCadastro.vue'),
      },
      {
        path: 'areas/:codigo',
        name: 'AreaCadastroEditar',
        meta: {
          titulo: 'Editar Área',
          identificadorPermissao: 'AREAS',
        },
        component: () => import(/* webpackChunkName: "areas" */ '@/views/Areas/AreaCadastro.vue'),
      },
      {
        path: 'formas-pagamentos',
        name: 'FormasPagamentos',
        meta: {
          titulo: 'Formas de Pagamentos',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoListagem.vue'),
      },
      {
        path: 'formas-pagamentos/cadastrar',
        name: 'FormaPagamentoCadastroCriar',
        meta: {
          titulo: 'Nova Forma de Pagamento',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoCadastro.vue'),
      },
      {
        path: 'formas-pagamentos/:codigo',
        name: 'FormaPagamentoCadastroEditar',
        meta: {
          titulo: 'Configurar Forma de Pagamento',
          identificadorPermissao: 'FORMAS_PAGAMENTOS',
        },
        component: () => import(/* webpackChunkName: "formas-pagamentos" */ '@/views/FormasPagamentos/FormaPagamentoCadastro.vue'),
      },
      {
        path: 'equipamentos',
        name: 'Equipamentos',
        meta: {
          titulo: 'Equipamentos',
          identificadorPermissao: 'EQUIPAMENTOS',
        },
        component: () => import(/* webpackChunkName: "equipamentos" */ '@/views/Equipamentos/EquipamentoListagem.vue'),
      },
      {
        path: 'equipamentos/cadastrar',
        name: 'EquipamentoCadastroCriar',
        meta: {
          titulo: 'Cadastrar Equipamento',
          identificadorPermissao: 'EQUIPAMENTOS',
        },
        component: () => import(/* webpackChunkName: "equipamentos" */ '@/views/Equipamentos/EquipamentoCadastro.vue'),
      },
      {
        path: 'equipamentos/:codigo',
        name: 'EquipamentoCadastroEditar',
        meta: {
          titulo: 'Editar Equipamento',
          identificadorPermissao: 'EQUIPAMENTOS',
        },
        component: () => import(/* webpackChunkName: "equipamentos" */ '@/views/Equipamentos/EquipamentoCadastro.vue'),
      },
      {
        path: 'reservas',
        name: 'Reservas',
        meta: {
          titulo: 'Reservas',
          identificadorPermissao: 'RESERVAS',
        },
        component: () => import(/* webpackChunkName: "reservas" */ '@/views/Reservas/ReservaListagem.vue'),
      },
      {
        path: 'reservas/cadastrar',
        name: 'ReservaCadastroCriar',
        meta: {
          titulo: 'Nova Reserva',
          identificadorPermissao: 'RESERVAS',
        },
        component: () => import(/* webpackChunkName: "reservas" */ '@/views/Reservas/ReservaCadastro.vue'),
      },
      {
        path: 'reservas/:codigo',
        name: 'ReservaCadastroEditar',
        meta: {
          titulo: 'Editar Reserva',
          identificadorPermissao: 'RESERVAS',
        },
        component: () => import(/* webpackChunkName: "equipamentos" */ '@/views/Reservas/ReservaCadastro.vue'),
      },
    ],
  },
];

export default rotasAplicacao;
